import { FC, useState, useContext, useRef } from "react";
import { Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";
import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";


const LeagalForm4: FC = () => {

    const {store} = useContext(Context);
    const [radioPlatformRoleStatus, setPlatformRoleStatus] = useState<string>(store.bank.platformRole ? store.bank.platformRole : '');
    const validated = false;
    const fileLimit = 10000000;

    // Data for Справка о гос.регистрации file
    const filePickerBankGosReg = useRef<HTMLInputElement>(null);
    const [errorFileSizeGosReg, setErrorGosReg] = useState<boolean>(false);
    const [filenameGosReg, setFilenameGosReg] = useState<string>('Загрузить скан-копию справки');
    const uploadGosRegDBelemnt = store.bank.uploadGosRegPath;

    // Data for Сведения о деловой репутации/репутация file
    const filePickerBankFounderDoc = useRef<HTMLInputElement>(null);
    const [errorFileSizeFounderDoc, setErrorFounderDoc] = useState<boolean>(false);
    const [filenameFounderDoc, setFilenameFounderDoc] = useState<string>('Загрузить скан-копию документов');
    const uploadFounderDocDBelemnt = store.bank.uploadFounderDocPath;

    
    
    const [form_Data, set_Form_Data] = useState({
        email: store.bank.email,
        currentBankAccount: store.bank.currentBankAccount,
        bankName: store.bank.bankName,
        bankBIK: store.bank.bankBIK,
        corrAccount: store.bank.corrAccount,
        bankInnCode: store.bank.bankInnCode,
        bankKPP: store.bank.bankKPP,
        platformRole: store.bank.platformRole,
        uploadGosReg: store.bank.uploadGosReg,
        uploadGosRegPath: uploadGosRegDBelemnt,
        uploadFounderDoc: store.bank.uploadFounderDoc,
        uploadFounderDocPath: uploadFounderDocDBelemnt,
    });

    const fileNameGosRegDB = uploadGosRegDBelemnt ? uploadGosRegDBelemnt.split('-f-n-').pop() : '';
    const requiredGosReg:boolean = uploadGosRegDBelemnt ? false : true;

    const fileNameFounderDocDB = uploadFounderDocDBelemnt ? uploadFounderDocDBelemnt.split('-f-n-').pop() : '';
    const requiredFounderDoc:boolean = uploadFounderDocDBelemnt ? false : true;

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const handleCheckboxChange = (score: any, inputName: string) => {
        setPlatformRoleStatus(score);

        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };

    const uploadHandleChnageGosReg = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorGosReg(true);
            
            if (filePickerBankGosReg.current) {
                filePickerBankGosReg.current.value = "";
            }
        } else {
            setFilenameGosReg(e.target.files[0].name);
            setErrorGosReg(false);
        }
    }

    const handlePickGosReg = () => {
        if (filePickerBankGosReg.current) {
            filePickerBankGosReg.current.click();
        }
    }

    const uploadHandleChnageFounderDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorFounderDoc(true);
            
            if (filePickerBankFounderDoc.current) {
                filePickerBankFounderDoc.current.value = "";
            }
        } else {
            setFilenameFounderDoc(e.target.files[0].name);
            setErrorFounderDoc(false);
        }
    }

    const handlePickFounderDoc = () => {
        if (filePickerBankFounderDoc.current) {
            filePickerBankFounderDoc.current.click();
        }
    }


    return ( <>
        <CardText className="mt-5">Банковские реквизиты</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                    <FormLabel className='required'>Расчетный счет</FormLabel>
                    <Form.Control
                        type="text"
                        name="currentBankAccount"
                        value={form_Data.currentBankAccount}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                        className='w-100'
                        required
                        placeholder='Расчетный счет'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.currentBankAccount)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationBankName">
                        <FormLabel className='required'>Наименование банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankName"
                            value={form_Data.bankName}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='Наименование банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankName)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                        <FormLabel className='required'>БИК банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankBIK"
                            value={form_Data.bankBIK}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='БИК банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankBIK)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationCorrAccount">
                        <FormLabel className='required'>Корреспондентский счет</FormLabel>
                        <Form.Control
                            type="text"
                            name="corrAccount"
                            value={form_Data.corrAccount}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='Корреспондентский счет'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.corrAccount)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationBankName">
                        <FormLabel className='required'>ИНН Банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankInnCode"
                            value={form_Data.bankInnCode}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='НИНН Банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankInnCode)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationCurrentBankAccount">
                        <FormLabel className='required'>КПП Банка</FormLabel>
                        <Form.Control
                            type="text"
                            name="bankKPP"
                            value={form_Data.bankKPP}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                            className='w-100'
                            required
                            placeholder='КПП Банка'
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.bankKPP)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </CardBody>
        </Card>
        <CardText className="mt-5">Дополнительная информация о юридическом лице</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Роль на платформе</FormLabel>
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="developRole"
                        checked={(radioPlatformRoleStatus === 'developRole' || radioPlatformRoleStatus === '') ? true : false}
                        onChange={e => handleCheckboxChange('developRole', 'platformRole')}
                        label="Производитель"
                        id="platformRoleDevelop"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="tradeRole"
                        checked={radioPlatformRoleStatus === 'tradeRole' ? true : false}
                        onChange={e => handleCheckboxChange('tradeRole', 'platformRole')}
                        label="Трейдер"
                        id="platformRoleTrade"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="platformRole"
                        value="investRole"
                        checked={radioPlatformRoleStatus === 'investRole' ? true : false}
                        onChange={e => handleCheckboxChange('investRole', 'platformRole')}
                        label="Инвестор"
                        id="platformRoleInvest"
                        className="radio"
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="validationBankGosReg">
                    <FormLabel>Справка о гос.регистрации</FormLabel>
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageGosReg}
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        required={requiredGosReg}
                        name="uploadGosReg"
                        ref={filePickerBankGosReg}
                        disabled={!requiredGosReg}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickGosReg}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameGosReg}
                            <div className="small-txt">Формат файла должен соответсвовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                        </div>
                        
                        {fileNameGosRegDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameGosRegDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeGosReg  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="validationBankFounderDoc">
                    <FormLabel>Учредительные документы</FormLabel>
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageFounderDoc}
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        required={requiredFounderDoc}
                        name="uploadFounderDoc"
                        ref={filePickerBankFounderDoc}
                        disabled={!requiredFounderDoc}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickFounderDoc}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameFounderDoc}
                            <div className="small-txt">Формат файла должен соответсвовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                        </div>
                        
                        {fileNameFounderDocDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameFounderDocDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeFounderDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
            </CardBody>
        </Card>
    </> );
}
 
export default LeagalForm4;