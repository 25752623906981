import React, { FC, useContext, useRef, useState } from "react";
import AccountMenu from "../parts/AccountMenu";
import {Context} from "../../../index";
import { observer } from "mobx-react-lite";
import { Form, Card, CardBody, CardTitle, Container, FormLabel, Col, Button, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalDefault from "../../modals/ModalDefault";
import infoDocument from "../../../img/icons/infoDocument.svg";
import plus from "../../../img/icons/plusGreen.svg";
import selectFile from "../../../img/icons/fileUpload.svg";
import ModalLink from "../../modals/ModalLink";
import URL from "../../../config/default.json"
import remove from "../../../img/icons/Trash.svg";

import Modal from 'react-bootstrap/Modal';
import envelope from "../../../img/icons/Envelope.svg";
import OrgAccountSubMenu from "../parts/OrgAccountSubMenu";

export const API_URL = URL.SERVER_URL_BASE;

const TechniqueForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    const filePickerTechnique = useRef<HTMLInputElement>(null);
    const handleModal = useRef<HTMLAnchorElement>(null);
    const [filenameTechnique, setFilenameTechnique] = useState<string>('');
    const [errorFileSizeTechnique, setErrorTechnique] = useState<boolean>(false);
    const years = [2024, 2023, 2022, 2021, 2020];

    const [imageTechniqueURL, setImageTechniqueURL] = useState<any>([]);
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};
    const fileLimitConfig = URL.FILE_LIMIT;
    const fileLimitPhotoConfig = URL.FILE_LIMIT_PHOTO;

    const [deleteId, setDeleteId] = useState('');
    const [deletePath, setDeletePath] = useState('');
    const [deletePhotoPath, setDeletePhotoPath] = useState([]);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);


    const fileLimit = fileLimitConfig;
    const fileLimitPhoto = fileLimitPhotoConfig;
    const photoAmountLimit = 5;
    const filePickerTechniquePhoto = useRef<HTMLInputElement>(null);
    const [errorFileSizeConfirmDoc, setErrorConfirmDoc] = useState<string>('');
    const [errorLimitCount, setErrorLimitCount] = useState<boolean>(false);
    const [filenameTechniquePhoto, setFilenameConfirmDoc] = useState<any>(['Загрузить фотографию']);

    const [form_Data, set_Form_Data] = useState({
        nameOfTechnique: '',
        stsOfTechnique: ''
    });

    const [form_Raio, set_Form_Radio] = useState({
        isTypeOfTechnique1: true,
        isTypeOfTechnique2: false,
        isTypeOfTechnique3: false,
        isTypeOfTechnique4: false,
    });

    const radioChange = function(par1:boolean, par2:boolean, par3:boolean, par4:boolean) {
        set_Form_Radio({
            isTypeOfTechnique1: par1,
            isTypeOfTechnique2: par2,
            isTypeOfTechnique3: par3,
            isTypeOfTechnique4: par4,
        })
    }

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            const isTypeOfTechnique = form.elements.isTypeOfTechnique.value;
            const nameOfTechnique = form.elements.nameOfTechnique.value;
            const yearOfTechnique = form.elements.yearOfTechnique.value;
            const stsOfTechnique = form.elements.stsOfTechnique.value;
            const dateAdded = form.elements.dateAdded.value;
            const uploadTechnique = form.elements.uploadTechnique.files[0];
            const uploadTechniquePhoto = form.elements.uploadTechniquePhoto.files;

            store.saveTechniqueInfo(store.user.email, store.user.id, isTypeOfTechnique, nameOfTechnique, yearOfTechnique, stsOfTechnique, dateAdded, uploadTechnique, uploadTechniquePhoto);
            set_Validated(false);

            set_Form_Data({
                nameOfTechnique: '',
                stsOfTechnique: ''
            });

            setFilenameTechnique('');
            setFilenameConfirmDoc(['Загрузить фотографию']);
            setImageTechniqueURL('');
            
            if (handleModal.current) {
                handleModal.current.click();
            }

            if (filePickerTechniquePhoto.current) {
                filePickerTechniquePhoto.current.value = "";
            }
        } else {
            set_Validated(true);
        }
        
    };

    const handlePickTechnique = () => {
        if (filePickerTechnique.current) {
            filePickerTechnique.current.click();
        }
   }

   const uploadHandleChnageTechnique = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorTechnique(true);
            
            if (filePickerTechnique.current) {
                filePickerTechnique.current.value = "";
            }
        } else {
            setFilenameTechnique(e.target.files[0].name);
            setErrorTechnique(false);
        }
    }

    function readFileAsText(file:any){
        return new Promise(function(resolve,reject){
            let fr = new FileReader();
            fr.onload = function(){
                resolve(fr.result);
            };
            fr.onerror = function(){
                reject(fr);
            };
            fr.readAsDataURL(file);
        });
    }

    const uploadHandleChnageTechniquePhoto = (e: any) => {
        let photos = e.target.files;
        var photoArr: any[] = [];
        var photoName: any[] = [];
        var errOneOfFile:string = '';
        if (photos.length > photoAmountLimit) {
            setErrorLimitCount(true);
            if (filePickerTechniquePhoto.current) {
                filePickerTechniquePhoto.current.value = "";
            }
        } else {
            setErrorLimitCount(false);
            Array.from(photos).forEach((photo: any) => {
                if (photo.size > fileLimitPhoto) {
                    errOneOfFile = photo.name;
                } else {
                    photoName.push(photo.name);
                    photoArr.push(readFileAsText(photo));

                    setFilenameConfirmDoc(photoName);
                    Promise.all(photoArr).then((values) => {
                        console.log(values[0]);
                        setImageTechniqueURL(values);
                    });
                }
            });
            setErrorConfirmDoc(errOneOfFile);
        }
    }

    const handlePickTechniquePhoto = () => {
        if (filePickerTechniquePhoto.current) {
            filePickerTechniquePhoto.current.click();
        }
    }

    const removeItem = () => {
        store.removeTechniqueItem(store.user.email, deleteId, deletePath, deletePhotoPath);
        setDeleteModal(false);
    }

    const removeItemSetData = (id:any, path:string, photoPath: []) => {
        setDeleteId(id);
        setDeletePath(path);
        setDeletePhotoPath(photoPath);
        setDeleteModal(true);
    }

    return ( <>
     {store.isAuth ? (<>
        <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4 active"/>
        <Container>
            <AccountMenu/>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <div className="load-harvest-doc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        accept=".pdf, .jpg, .jpeg, .png, .heic"
                        name="uploadTechnique"
                        onChange={uploadHandleChnageTechnique}
                        ref={filePickerTechnique}
                    />
                    <div className="filePicker px-0 py-4">
                        <div className="d-flex align-items-center justify-content-center gap-3">
                            <Button onClick={handlePickTechnique}>Загрузить файл</Button>
                            <ModalDefault name="Заполнить автоматически через Росреестр" class="ms-5 btn-outline-wrap-gold"/>
                            <img src={infoDocument} alt="File Information"/>
                        </div>
                        <Row className="d-flex align-items-center justify-content-center">
                            <Col lg={12}>
                                <div className="fileLable mt-3 text-center">{filenameTechnique}
                                    <div className="small-txt">Формат файла должен соответсвовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                                </div>
                            </Col>
                            {errorFileSizeTechnique  ? <Col lg={12}><Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit / URL.FILE_LIMIT} Mb</Alert></Col> : ''}
                        </Row>
                    </div>
                </div>
                <CardTitle>Добавить технику</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        <Form.Group as={Col} className="mb-3">
                            <FormLabel>Укажите тип техники</FormLabel>
                            <Form.Check
                                type="radio"
                                name="isTypeOfTechnique"
                                value="transport"
                                label="Транспорт"
                                id="isTypeOfTechnique1"
                                className="radio"
                                checked={form_Raio.isTypeOfTechnique1}
                                onChange={e => radioChange(true, false, false, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="isTypeOfTechnique"
                                value="tractor"
                                label="Трактор"
                                id="isTypeOfTechnique2"
                                className="radio"
                                checked={form_Raio.isTypeOfTechnique2}
                                onChange={e => radioChange(false, true, false, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="isTypeOfTechnique"
                                value="trailer"
                                label="Прицепные"
                                id="isTypeOfTechnique3"
                                className="radio"
                                checked={form_Raio.isTypeOfTechnique3}
                                onChange={e => radioChange(false, false, true, false)}
                            />
                            <Form.Check
                                type="radio"
                                name="isTypeOfTechnique"
                                value="specialEquipment"
                                label="Специальное оборудование"
                                id="isTypeOfTechnique4"
                                className="radio"
                                checked={form_Raio.isTypeOfTechnique4}
                                onChange={e => radioChange(false, false, false, true)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationCadastralNumber">
                            <FormLabel className='required'>Укажите наименование</FormLabel>
                            <Form.Control
                                type="text"
                                name="nameOfTechnique"
                                value={form_Data.nameOfTechnique}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Пример'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationplantasName">
                            <FormLabel className='required'>Укажите год выпуска</FormLabel>
                            <Form.Select
                                name="yearOfTechnique"
                                className='w-100 legal-form-1'
                                required
                            >
                                <option value="">Выберите необходимое</option>
                                {years.map(function(data, idx){
                                    return  (<option value={data}>{data}</option>);
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="validationCadastralNumber">
                            <FormLabel className='required'>СТС</FormLabel>
                            <Form.Control
                                type="text"
                                name="stsOfTechnique"
                                className='w-100 legal-form-1'
                                value={form_Data.stsOfTechnique}
                                onChange={chngFn}
                                required
                                placeholder='Пример'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group as={Col} className="mb-3" controlId="validationLicenseConfirmDoc">
                            <Form.Control 
                                type="file"
                                className="hidden"
                                multiple
                                onChange={uploadHandleChnageTechniquePhoto}
                                accept=".jpg, .jpeg, .png, .heic"
                                name="uploadTechniquePhoto"
                                ref={filePickerTechniquePhoto}
                            />
                            
                            <div className="filePicker d-flex align-items-center">
                                <div className="me-4 fileIcon" onClick={handlePickTechniquePhoto}><img src={selectFile} alt="Select file"/></div>
                                <div className="fileLable">{filenameTechniquePhoto.map(function (data:string){return (<div className="me-4">{data}</div>)})}
                                    <div className="small-txt">Формат файла должен соответсвовать [.jpg, .jpeg, .png, .heic]</div>
                                </div>
                                
                                {errorFileSizeConfirmDoc !== '' ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла {errorFileSizeConfirmDoc} больше лимита в {fileLimitPhoto / URL.FILE_LIMIT_PHOTO} Mb</Alert> : ''}
                                {errorLimitCount ? <Alert className="d-flex w-100 mt-3" variant="danger">Количество файлов больше лимита в {photoAmountLimit} штук</Alert> : ''}
                            </div>
                            {imageTechniqueURL ? (
                                imageTechniqueURL.map(function(data:any){
                                    return (<>
                                        <div className="d-inline-block me-4"><div className="photo-land-img"><img src={data} alt=''/></div></div>
                                    </>);
                                })
                            ) : (<></>)}
                        </Form.Group>
                        <Form.Control
                            type="text"
                            name="dateAdded"
                            value={new Date().toISOString().slice(0, 10).split('-').reverse().join('/')}
                            className='hidden'
                        />
                        <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Сохранить</Button>
                    </CardBody>
                </Card>
                <Link to="/" onClick={handleShow} ref={handleModal}></Link>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <h4>Даные добавлены</h4>
                    <button className='btn btn-primary d-flex align-item-center mt-4' onClick={handleClose}>Закрыть</button>
                    </Modal.Body>
                    <Modal.Footer>
                    <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                    <div className='btn-outline-wrap-green mt-3 mb-4'>
                    <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>

            <div className="d-flex justify-content-center my-5"><img src={plus} alt="File Information"/></div>
            {store.techniqueInfo && store.techniqueInfo.technique && store.techniqueInfo.technique.length >0 ? (<>
                <CardTitle className="mt-5">Добавленная техника</CardTitle>
                <Card className="mb-3">
                    <CardBody>
                        {store.techniqueInfo.technique.map(function(data, idx){
                            const uploadTechniqueDBelemnt = data.uploadTechniquePath;
                            const fileNameCopyScaDB = uploadTechniqueDBelemnt ? uploadTechniqueDBelemnt.split('-f-n-').pop() : '';
                            let typeOfTechnique = 'Транспорт';
                            if (data.isTypeOfTechnique === 'tractor') {
                                typeOfTechnique = 'Трактор';
                            } else if (data.isTypeOfTechnique === 'trailer') {
                                typeOfTechnique = 'Прицепные';
                            } else if (data.isTypeOfTechnique === 'specialEquipment') {
                                typeOfTechnique = 'Специальное оборудование';
                            }

                            
                            return (<div className="harvest-data" key={idx}>
                                    <div className="harvest-data-top mb-3">{data.nameOfTechnique}</div>
                                    <Row className="harvest-data-bottom">
                                        <Col lg={2} ><div className="harvest-data-bottom-item"><span>{typeOfTechnique} </span><span>Тип техники</span></div></Col>
                                        <Col lg={2}><div className="harvest-data-bottom-item"><span>{data.yearOfTechnique}</span><span>год выпуска</span></div></Col>
                                        {data.uploadTechniquePhotoPath ? (<>
                                        <Col lg={fileNameCopyScaDB ? 5 : 7}><div className="photo-technique-img loaded d-flex">
                                            {data.uploadTechniquePhotoPath.map(function (photo:any){
                                                const uploadTechniquePhotoPath = photo ? photo.split('uploads/').pop() : '';
                                                return (<div><div className="d-flex"><img src={API_URL + uploadTechniquePhotoPath} alt={data.isTypeOfTechnique} /><ModalLink action="remove" /></div></div>);
                                            })}
                                            </div>
                                        </Col>
                                        </>): (<></>)}
                                        {fileNameCopyScaDB ? <Col lg={2}><div className="harvest-data-bottom-item"><span>Загруженные файлы</span><span>{fileNameCopyScaDB}</span></div></Col> : ''}
                                        <Col lg={1}><div className="harvest-data-bottom-item remove">
                                        <Link to="" onClick={e => removeItemSetData(data._id, data.uploadTechniquePath, data.uploadTechniquePhotoPath)}><img src={remove} alt="remove"/></Link></div>
                                        </Col>
                                    </Row>
                                </div>)
                        }) }
                    </CardBody>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                        <h4>Подвердите удаление данных</h4>
                        </Modal.Body>
                        <Modal.Footer>
                        <p className="mb-4">Данные будут удалены и востановлению не подлежат</p>
                        <Row className="w-100">
                            <Col className="ps-0" lg={3}>
                                <button className='btn btn-primary mt-3 mb-4 w-100 text-center' onClick={removeItem}>Да</button>
                            </Col>
                            <Col className="ps-0" lg={3}>
                            <div className='btn-outline-wrap-green mt-3 mb-4 w-100 text-center'> 
                                <button className='btn btn-outline' onClick={handleCloseDeleteModal}>Нет</button>
                            </div>
                            </Col>
                        </Row>
                        </Modal.Footer>
                    </Modal>
                </Card>
            </>) : <></>}
        </Container>
    </>) : <></>}
    </> );
}
 
export default observer(TechniqueForm);