import {FC, useContext} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'react-bootstrap';

import emitImage from "../../img/icons/emiter-default.svg"
const MyCfa:FC = () => {
    const {store} = useContext(Context);
    const countEl = 4;

    return (<>
            {store.isAuth ? (<>
                <CfaAccountSubMenu class1="organization-link px-4 active" class2="organization-link px-4" class3="organization-link px-4"/>
                <Container><h2 className='mt-5'>Мои ЦФА</h2>
                    <Row className="cfa-table-header mt-5 me-0 ms-0">
                        <Col lg={4}>Наименование</Col>
                        <Col lg={3}>Эмитент</Col>
                        <Col className="text-center" lg={1}>Количество</Col>
                        <Col className="text-end" lg={1}>Дата погашения</Col>
                        <Col className="text-end pe-4" lg={1}>Цена погашения</Col>
                        <Col className="text-center" lg={2}>Сумма</Col>
                    </Row>
                    {(() => {
                        const element = [];

                        for (let i = 1; i <= countEl; i++) {
                            element.push(
                            <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                <Col className="ps-0" lg={4}>
                                    <div className='cfa-table-title'>
                                        <div className='cfa-table-sub-title'>Digital-Grain_{i}00</div>
                                        <div className='cfa-table-name'>ООО «Поле» — денежное требование №{i}</div>
                                    </div>
                                </Col>
                                <Col lg={3} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>ООО «Поле»</span></Col>
                                <Col className="text-center" lg={1}>1{i}</Col>
                                <Col className="text-end" lg={1}>{new Date().toISOString().slice(0, 10).split('-').reverse().join('.')}</Col>
                                <Col className="text-end pe-4" lg={1}>{i} 000 000</Col>
                                <Col className="text-center primary-border" lg={2}>{i}{i} 000 000</Col>
                            </Row>);
                        }

                    return element;
                    })()}

                    <CardTitle className='mt-5'>Последние операции с ЦФА</CardTitle>
                    <Card className="mb-3">
                        <CardBody>
                            <div className='cfa-operation-day'>
                                <div className='cfa-operation-title'>
                                    <Row className="cfa-table-header me-0 ms-0">
                                        <Col className="text-end" lg={9}></Col>
                                        <Col className="text-center" lg={1}>Количество</Col>
                                        <Col className="text-center" lg={2}>Сумма сделки</Col>
                                    </Row>
                                </div>
                                <div className='cfa-operation-day-item'>
                                    <div className='cfa-operation-day-item-data mt-4'>{new Date('05/10/2024').toISOString().slice(0, 10).split('-').reverse().join('.')}</div>
                                    {(() => {
                                        const element = [];

                                        for (let i = 1; i <= countEl; i++) {
                                            element.push(
                                            <Row className={i === 2 ? 'align-items-center cfa-table-body mt-4 me-0 ms-0 sell' : 'align-items-center cfa-table-body mt-4 me-0 ms-0'}>
                                                <Col className="ps-0" lg={3}>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='cfa-table-sub-time me-3'>10:{i}0</div>
                                                        <img className="me-3 field-img" src={emitImage} alt="Эмитент"/>
                                                        <div>
                                                            <div className='cfa-table-sub-title'>Digital-Grain_{i}00</div>
                                                            <div className='cfa-table-name'>ООО «Поле»</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} className='cfa-table-title '><span>{i === 2 ? 'Продажа' : 'Первичное приобретение'} </span></Col>
                                                <Col className="text-end pe-4" lg={1}>{i} шт</Col>
                                                <Col className="text-center sell-price" lg={2}>{i === 2 ? '+' : '-'}{i}{i} 000 000 руб.</Col>
                                            </Row>);
                                        }

                                    return element;
                                    })()}
                                </div>
                            </div>
                            <div className='cfa-operation-day mt-4'>
                                <div className='cfa-operation-day-item'>
                                    <div className='cfa-operation-day-item-data mt-4'>{new Date('04/10/2024').toISOString().slice(0, 10).split('-').reverse().join('.')}</div>
                                    {(() => {
                                        const element = [];

                                        for (let i = 1; i <= countEl; i++) {
                                            element.push(
                                            <Row className={i === 3 ? 'align-items-center cfa-table-body mt-4 me-0 ms-0 sell' : 'align-items-center cfa-table-body mt-4 me-0 ms-0'}>
                                                <Col className="ps-0" lg={3}>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='cfa-table-sub-time me-3'>10:{i}0</div>
                                                        <img className="me-3 field-img" src={emitImage} alt="Эмитент"/>
                                                        <div>
                                                            <div className='cfa-table-sub-title'>Digital-Grain_{i}00</div>
                                                            <div className='cfa-table-name'>ООО «Поле»</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} className='cfa-table-title '><span>{i === 3 ? 'Продажа' : 'Первичное приобретение'} </span></Col>
                                                <Col className="text-end pe-4" lg={1}>{i} шт</Col>
                                                <Col className="text-center sell-price" lg={2}>{i === 3 ? '+' : '-'}{i}{i} 000 000 руб.</Col>
                                            </Row>);
                                        }

                                    return element;
                                    })()}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Container>

            </>) : (<></>)}
        </>
    );
}
 
export default observer(MyCfa);