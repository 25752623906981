import {FC, useContext } from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../index";
import { Container } from 'react-bootstrap';
import AccountNewInformation from '../components/account/AccountNewInformation';
import OrgAccountSubMenu from '../components/account/parts/OrgAccountSubMenu';


const Information: FC = () => {
    const {store} = useContext(Context);

    return (<>
            {store.isAuth ? (
            <OrgAccountSubMenu class1="organization-link px-4" class2="organization-link px-4 active"/>) : <></>}
            <Container>
                {store.user.isActivated ? <AccountNewInformation/> : ''}
                
                <div className="text-center">
                    <h2 className='my-5'>{store.isAuth ? '' : 'АВТОРИЗУЙТЕСЬ'}</h2>
                    {store.isAuth && !store.user.isActivated ? (<>
                        <h3 className='my-5'>{store.user.name}, добро пожаловать на платформу. <br/><br/>
                        Для продолжения регистрации перейдите, пожалуйста, по ссылке из письма, отправленного на указанный Вами адрес электронной почты.</h3>
                    </>) : (<></>)}
                </div>
            </Container>
        </>
    );
};
export default observer(Information);