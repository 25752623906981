import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";

const StocksDetail: FC = () => {
    const {store} = useContext(Context);
    function showStocksItem(count:any, variant = '') {
        var stocks:any = store.stocksInfo.stocks;

        return (<>
            {stocks.map(function(data:any, idx:any){
                if (idx > count) {
                    return false;
                }
                
                return (
                    <div className="harvest-data p-0 pt-2" key={idx}>
                        <Row className="harvest-data-bottom">
                            <Col lg={2} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{data.countOfGrain} т обьем</span><span>{data.typeOfGrain}</span></div></Col>
                            <Col lg={9}><div className="harvest-data-bottom-item-info"><span>Качества</span><span>{data.qualityOfGrain}</span></div></Col>
                        </Row>
                    </div>)
                }) 
            }
        </>);
    }
    return ( <>
     {store.isAuth && store.stocksInfo && store.stocksInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title"  to="/stocks"><img className="me-3" src={selectFile} alt="Складские запасы"/>Складские запасы</Link></Col>
        <div id="info-tab-example" className="w-100 mb-3" >
            {showStocksItem(2)}
        </div>
        
    </>) : <></>}
    </> );
}
 
export default observer(StocksDetail);