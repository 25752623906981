import { FC, useContext, useState, useRef } from "react";
import {Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";
import { ICompany } from "../../../models/ICompany";

import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";

const LeagalForm1: FC = () => {
    const {store} = useContext(Context);
    const validated = false;
    const fileLimit = 10000000;
    const [filenameOrgn, setFilenameOrgn] = useState<string>('Загрузить скан-копию ОГРН');
    const [filenameInn, setFilenameInn] = useState<string>('Загрузить скан-копию ИНН');
    const [errorFileSizeOrgn, setErrorOrgn] = useState<boolean>(false);
    const [errorFileSizeInn, setErrorInn] = useState<boolean>(false);
    const filePickerOrgn = useRef<HTMLInputElement>(null);
    const filePickerInn = useRef<HTMLInputElement>(null);
    const [radioPostStatus, setRadioPostStatus] = useState<string>(store.company.isPostAddSameAsGos)
    const [radioLegalStatus, setRadioLegalStatus] = useState<string>(store.company.isLegalAddSameAsGos)

    const uploadInnDBelemnt = store.company.uploadInnPath;
    const uploadOgrnDBelemnt = store.company.uploadOgrnPath;

    const [form_Data, set_Form_Data] = useState<ICompany>({
        email: store.company.email,
        organizationalLegalFor: store.company.organizationalLegalFor,
        shortCompanyName: store.company.shortCompanyName,
        companyPhone: store.company.companyPhone,
        companyEmail: store.company.companyEmail,
        companyWebSite: store.company.companyWebSite,
        ogrnName: store.company.ogrnName,
        isValidOrgnDocument: false,
        innName: store.company.innName,
        isValidInnDocument: false,
        uploadOgrn: store.company.uploadOgrn,
        uploadInn: store.company.uploadInn,
        uploadOgrnPath: uploadOgrnDBelemnt,
        uploadInnPath: uploadInnDBelemnt,
        kppName: store.company.kppName,
        okpoName: store.company.okpoName,
        okatoName: store.company.okatoName,
        okvedName: store.company.okvedName,
        regDate: store.company.regDate,
        nameRegAuthority: store.company.nameRegAuthority,
        gosRegRegion: store.company.gosRegRegion,
        gosRegCity: store.company.gosRegCity,
        gosRegStreet: store.company.gosRegStreet,
        gosRegHouse: store.company.gosRegHouse,
        gosRegCorpus: store.company.gosRegCorpus,
        gosRegApp: store.company.gosRegApp,
        gosPostalCode: store.company.gosPostalCode,
        isLegalAddSameAsGos: store.company.isLegalAddSameAsGos,
        isPostAddSameAsGos: store.company.isPostAddSameAsGos,
        legalRegRegion: store.company.legalRegRegion,
        legalRegCity: store.company.legalRegCity,
        postRegRegion: store.company.postRegRegion,
        postRegCity: store.company.postRegCity,
        legalRegStreet: store.company.legalRegStreet,
        legalRegHouse: store.company.legalRegHouse,
        legalRegCorpus: store.company.legalRegCorpus,
        legalRegApp: store.company.legalRegApp,
        legalPostalCode: store.company.legalPostalCode,
        postRegStreet: store.company.postRegStreet,
        postRegHouse: store.company.postRegHouse,
        postRegCorpus: store.company.postRegCorpus,
        postRegApp: store.company.postRegApp,
        postPostalCode: store.company.postPostalCode
        
    });


    const requiredInn:boolean = uploadInnDBelemnt ? false : true;
    const requiredOgrn:boolean = uploadOgrnDBelemnt ? false : true;

    const fileNameInnDB = uploadInnDBelemnt ? uploadInnDBelemnt.split('-f-n-').pop() : '';
    const fileNameOgrnDB = uploadOgrnDBelemnt ? uploadOgrnDBelemnt.split('-f-n-').pop() : '';
    
    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const uploadHandleChnageOrgn = (e: any) => {
        if (e.target.files[0].size > fileLimit) {
            setErrorOrgn(true);
            
            if (filePickerOrgn.current) {
                filePickerOrgn.current.value = "";
            }
        } else {
            setFilenameOrgn(e.target.files[0].name);
            setErrorOrgn(false);
        }
    }

    const uploadHandleChnageInn = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorInn(true);
            
            if (filePickerInn.current) {
                filePickerInn.current.value = "";
            }
        } else {
            setFilenameInn(e.target.files[0].name);
            setErrorInn(false);
        }
    }

   const handlePickOrgn = () => {
        if (filePickerOrgn.current) {
            filePickerOrgn.current.click();
        }
   }

   const handlePickInn = () => {
        if (filePickerInn.current) {
            filePickerInn.current.click();
        }
    }

    const handleRadioPostChange = (score: any, inputName: string) => {
        setRadioPostStatus(score);
        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
        if (score === 'true') {
            let formDataOrgReg = form_Data.gosRegRegion;
            let formDataOrgCity = form_Data.gosRegCity;
            let formDataOrgStreet = form_Data.gosRegStreet;
            let formDataOrgHouse = form_Data.gosRegHouse;
            let formDataOrgCorpus = form_Data.gosRegCorpus;
            let formDataOrgApp = form_Data.gosRegApp;
            let formDataOrgPostalCode = form_Data.gosPostalCode;
            set_Form_Data({
                ...form_Data,
                'postRegRegion': formDataOrgReg,
                'postRegCity': formDataOrgCity,
                'postRegStreet': formDataOrgStreet,
                'postRegHouse': formDataOrgHouse,
                'postRegCorpus': formDataOrgCorpus,
                'postRegApp': formDataOrgApp,
                'postPostalCode': formDataOrgPostalCode,
            });
        } else {
            let formDataOrgReg = form_Data.postRegRegion ? form_Data.postRegRegion : '';
            let formDataOrgCity = form_Data.postRegCity ? form_Data.postRegCity : '';
            let formDataOrgStreet = form_Data.postRegStreet ? form_Data.postRegStreet : '';
            let formDataOrgHouse = form_Data.postRegHouse ? form_Data.postRegHouse : '';
            let formDataOrgCorpus = form_Data.postRegCorpus ? form_Data.postRegCorpus: '';
            let formDataOrgApp = form_Data.postRegApp ? form_Data.postRegApp: '';
            let formDataOrgPostalCode = form_Data.postPostalCode ? form_Data.postPostalCode : '' ;
            set_Form_Data({
                ...form_Data,
                'postRegRegion': formDataOrgReg,
                'postRegCity': formDataOrgCity,
                'postRegStreet': formDataOrgStreet,
                'postRegHouse': formDataOrgHouse,
                'postRegCorpus': formDataOrgCorpus,
                'postRegApp': formDataOrgApp,
                'postPostalCode': formDataOrgPostalCode,
            });
        }
    };
    const handleRadioLegalChange = (score: any, inputName: string) => {
        setRadioLegalStatus(score);
        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });

        if (score === 'true') {
            let formDataOrgReg = form_Data.gosRegRegion;
            let formDataOrgCity = form_Data.gosRegCity;
            let formDataOrgStreet = form_Data.gosRegStreet;
            let formDataOrgHouse = form_Data.gosRegHouse;
            let formDataOrgCorpus = form_Data.gosRegCorpus;
            let formDataOrgApp = form_Data.gosRegApp;
            let formDataOrgPostalCode = form_Data.gosPostalCode;
            set_Form_Data({
                ...form_Data,
                'legalRegRegion': formDataOrgReg,
                'legalRegCity': formDataOrgCity,
                'legalRegStreet': formDataOrgStreet,
                'legalRegHouse': formDataOrgHouse,
                'legalRegCorpus': formDataOrgCorpus,
                'legalRegApp': formDataOrgApp,
                'legalPostalCode': formDataOrgPostalCode,
            });
        } else {
            let formDataOrgReg = form_Data.legalRegRegion ? form_Data.legalRegRegion : '';
            let formDataOrgCity = form_Data.legalRegCity ? form_Data.legalRegCity : '';
            let formDataOrgStreet = form_Data.legalRegStreet ? form_Data.legalRegStreet  : '';
            let formDataOrgHouse = form_Data.legalRegHouse ? form_Data.legalRegHouse : '';
            let formDataOrgCorpus = form_Data.legalRegCorpus ? form_Data.legalRegCorpus : '';
            let formDataOrgApp = form_Data.legalRegApp ? form_Data.legalRegApp : '';
            let formDataOrgPostalCode = form_Data.legalPostalCode ? form_Data.legalPostalCode : '';
            set_Form_Data({
                ...form_Data,
                'legalRegRegion': formDataOrgReg,
                'legalRegCity': formDataOrgCity,
                'legalRegStreet': formDataOrgStreet,
                'legalRegHouse': formDataOrgHouse,
                'legalRegCorpus': formDataOrgCorpus,
                'legalRegApp': formDataOrgApp,
                'legalPostalCode': formDataOrgPostalCode,
            });
        }
    };

    return ( <>
        <CardText className="mt-5">Основные сведения</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationOrganizationalLegalFor">
                    <FormLabel className='required'>Организационно-правовая форма</FormLabel>
                    <Form.Control
                        type="text"
                        name="organizationalLegalFor"
                        value={form_Data.organizationalLegalFor}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                        className='w-100 legal-form-1'
                        required
                        placeholder='Организационно-правовая форма'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.organizationalLegalFor)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="validationShortCompanyName">
                    <FormLabel className='required'>Краткое наименование организации</FormLabel>
                    <Form.Control
                        type="text"
                        name="shortCompanyName"
                        value={form_Data.shortCompanyName}
                        onChange={chngFn}
                        className='w-100 legal-form-1'
                        required
                        placeholder='Краткое наименование организации'
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationCompanyPhone">
                        <FormLabel className='required'>Телефон</FormLabel>
                        <Form.Control
                            type="text"
                            name="companyPhone"
                            value={form_Data.companyPhone}
                            onChange={chngFn}
                            pattern="^\+[0-9]{9,15}$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='+7'
                            isInvalid={ validated && !/^\+[0-9]{9,15}$/.test(form_Data.companyPhone)}
                        />
                        <Form.Control.Feedback type="invalid">
                        Телефон должен быть в формате +7
                        </Form.Control.Feedback>
                    </Form.Group>
                
                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationCompanyEmail">
                        <FormLabel className='required'>Электронная почта</FormLabel>
                        <Form.Control
                            type="text"
                            name="companyEmail"
                            value={form_Data.companyEmail}
                            onChange={chngFn}
                            pattern="^\S+@\S+\.\S+$"
                            className='w-100 legal-form-1'
                            required
                            placeholder='Электронная почта'
                            isInvalid={ validated && !/^\S+@\S+\.\S+$/.test(form_Data.companyEmail)}
                        />
                        <Form.Control.Feedback type="invalid">
                        Введите корректную почту
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationCompanyWebSite">
                        <FormLabel>Сайт</FormLabel>
                        <Form.Control
                            type="text"
                            name="companyWebSite"
                            value={form_Data.companyWebSite}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Сайт'
                        />
                    </Form.Group>
                </Row>
            </CardBody>
        </Card>
        <CardText className="mt-5">Реквизиты организации</CardText>
        <Card>
            <CardBody>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationOgrnNamer">
                        <FormLabel className='required'>ОГРН</FormLabel>
                        <Form.Control
                            type="text"
                            name="ogrnName"
                            value={form_Data.ogrnName}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='ОГРН'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationInnNamer">
                        <FormLabel className='required'>ИНН</FormLabel>
                        <Form.Control
                            type="text"
                            name="innName"
                            value={form_Data.innName}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='ИНН'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>

                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationUploadOgrn">
                        <Form.Control 
                            type="file"
                            className="hidden"
                            onChange={uploadHandleChnageOrgn}
                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                            required={requiredOgrn}
                            name="uploadOgrn"
                            ref={filePickerOrgn}
                            disabled={!requiredOgrn}
                        />
                        <div className="filePicker d-flex align-items-center">
                            <div className="me-4 fileIcon" onClick={handlePickOrgn}><img src={selectFile} alt="Select file"/></div>
                            <div className="fileLable">{filenameOrgn}
                                <div className="small-txt">Формат файла должен соответсвовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                            </div>
                            {fileNameOgrnDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameOgrnDB} находится в обработке</Alert> : ''}
                            {errorFileSizeOrgn  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Файл обязателен к загрузке
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationUploadInn">
                        <Form.Control 
                            type="file"
                            className="hidden"
                            onChange={uploadHandleChnageInn}
                            accept=".pdf, .jpg, .jpeg, .png, .heic"
                            required={requiredInn}
                            name="uploadInn"
                            ref={filePickerInn}
                            disabled={!requiredInn}
                        />
                        
                        <div className="filePicker d-flex align-items-center">
                            <div className="me-4 fileIcon" onClick={handlePickInn}><img src={selectFile} alt="Select file"/></div>
                            <div className="fileLable">{filenameInn}
                                <div className="small-txt">Формат файла должен соответсвовать [.pdf, .jpg, .jpeg, .png, .heic]</div>
                            </div>
                            
                            {fileNameInnDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameInnDB} находится в обработке</Alert> : ''}
                            
                            {errorFileSizeInn  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Файл обязателен к загрузке
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationKpp">
                        <FormLabel className='required'>КПП</FormLabel>
                        <Form.Control
                            type="text"
                            name="kppName"
                            value={form_Data.kppName}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='КПП'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationOkpo">
                        <FormLabel className='required'>ОКПО</FormLabel>
                        <Form.Control
                            type="text"
                            name="okpoName"
                            value={form_Data.okpoName}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='ОКПО'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationOkato">
                        <FormLabel className='required'>ОКАТО</FormLabel>
                        <Form.Control
                            type="text"
                            name="okatoName"
                            value={form_Data.okatoName}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='ОКАТО'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-3" controlId="validationOkved">
                    <FormLabel className='required'>ОКВЭД</FormLabel>
                    <Form.Control
                        type="text"
                        name="okvedName"
                        value={form_Data.okvedName}
                        onChange={chngFn}
                        className='w-100 legal-form-1'
                        required
                        placeholder='ОКВЭД'
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Form.Group lg={3} as={Col} className="mb-3" controlId="validationRegDate">
                        <FormLabel className='required'>Дата регистрации</FormLabel>
                        <Form.Control
                            type="date"
                            max={new Date().toISOString().slice(0, 10)}
                            defaultValue={form_Data.regDate}
                            onChange={chngFn}
                            name="regDate"
                            className='w-100 legal-form-1'
                            required
                            placeholder='дд.мм.гггг'
                        />

                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={9} as={Col} className="mb-3" controlId="validationNameRegAuthority">
                        <FormLabel>Наименование регистрирующего органа</FormLabel>
                        <Form.Control
                            type="text"
                            name="nameRegAuthority"
                            value={form_Data.nameRegAuthority}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Наименование регистрирующего органа'
                        />
                    </Form.Group>
                </Row>
                <div className="formGroupInsideTitle my-4">Адрес государственной регистрации</div>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationGosRegRegion">
                        <FormLabel className='required'>Регион</FormLabel>
                        <Form.Control
                            type="text"
                            name="gosRegRegion"
                            value={form_Data.gosRegRegion}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Регион'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationGosRegCity">
                        <FormLabel className='required'>Населенный пункт</FormLabel>
                        <Form.Control
                            type="text"
                            name="gosRegCity"
                            value={form_Data.gosRegCity}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Населенный пункт'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group lg={4} as={Col} className="mb-3" controlId="validationGosRegStreet">
                        <FormLabel className='required'>Улица</FormLabel>
                        <Form.Control
                            type="text"
                            name="gosRegStreet"
                            value={form_Data.gosRegStreet}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Улица'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationGosRegHouse">
                        <FormLabel className='required'>Дом</FormLabel>
                        <Form.Control
                            type="text"
                            name="gosRegHouse"
                            value={form_Data.gosRegHouse}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Дом'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationGosRegCorpus">
                        <FormLabel >Корпус</FormLabel>
                        <Form.Control
                            type="text"
                            name="gosRegCorpus"
                            value={form_Data.gosRegCorpus}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Корпус'
                        />
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationGosRegApp">
                        <FormLabel>Квартира</FormLabel>
                        <Form.Control
                            type="text"
                            name="gosRegApp"
                            value={form_Data.gosRegApp}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            placeholder='Квартира'
                        />
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validationGosPostalCode">
                        <FormLabel className='required'>Индекс</FormLabel>
                        <Form.Control
                            type="text"
                            name="gosPostalCode"
                            value={form_Data.gosPostalCode}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Индекс'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <div className="formGroupInsideTitle my-4">Адрес юридического лица</div>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Совпадает с aдресом государственной регистрации</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isLegalAddSameAsGos"
                        value={radioLegalStatus || 'false'}
                        checked={radioLegalStatus === 'true' ? true : false}
                        onChange={e => handleRadioLegalChange('true', 'isLegalAddSameAsGos')}
                        label="Совпадает"
                        id="isLegalAddSameAsGosTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="isLegalAddSameAsGos"
                        value={radioLegalStatus || 'false'}
                        checked={radioLegalStatus === 'true' ? false : true}
                        onChange={e => handleRadioLegalChange('false', 'isLegalAddSameAsGos')}
                        label="Не Совпадает"
                        id="isLegalAddSameAsGosFalse"
                        className="radio"
                    />
                </Form.Group>

                <Row className={radioLegalStatus === 'true' ? 'legalAddressContainer hidden' : 'legalAddressContainer'}>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationLegalRegRegion">
                        <FormLabel className='required'>Регион</FormLabel>
                        <Form.Control
                            type="text"
                            name="legalRegRegion"
                            value={form_Data.legalRegRegion}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Регион'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationLegalRegCity">
                        <FormLabel className='required'>Населенный пункт</FormLabel>
                        <Form.Control
                            type="text"
                            name="legalRegCity"
                            value={form_Data.legalRegCity}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Населенный пункт'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Form.Group lg={4} as={Col} className="mb-3" controlId="validationLegalRegStreet">
                            <FormLabel className='required'>Улица</FormLabel>
                            <Form.Control
                                type="text"
                                name="legalRegStreet"
                                value={form_Data.legalRegStreet}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Улица'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationLegalRegHouse">
                            <FormLabel className='required'>Дом</FormLabel>
                            <Form.Control
                                type="text"
                                name="legalRegHouse"
                                value={form_Data.legalRegHouse}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Дом'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationLegalRegCorpus">
                            <FormLabel >Корпус</FormLabel>
                            <Form.Control
                                type="text"
                                name="legalRegCorpus"
                                value={form_Data.legalRegCorpus}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Корпус'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationLegalRegApp">
                            <FormLabel>Квартира</FormLabel>
                            <Form.Control
                                type="text"
                                name="legalRegApp"
                                value={form_Data.legalRegApp}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Квартира'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationLegalPostalCode">
                            <FormLabel className='required'>Индекс</FormLabel>
                            <Form.Control
                                type="text"
                                name="legalPostalCode"
                                value={form_Data.legalPostalCode}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Индекс'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Row>
                
                <div className="formGroupInsideTitle my-4">Почтовый адрес</div>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Совпадает с aдресом государственной регистрации</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isPostAddSameAsGos"
                        value={radioPostStatus || 'false'}
                        checked={radioPostStatus === 'true' ? true : false}
                        onChange={e => handleRadioPostChange('true', 'isPostAddSameAsGos')}
                        label="Совпадает"
                        id="isPostAddSameAsGosTrue"
                        className="radio"
                    />
                    
                    <Form.Check
                        type="radio"
                        name="isPostAddSameAsGos"
                        value={radioPostStatus || 'false'}
                        checked={radioPostStatus === 'true' ? false : true}
                        onChange={e => handleRadioPostChange('false', 'isPostAddSameAsGos')}
                        label="Не Совпадает"
                        id="isPostAddSameAsGosFalse"
                        className="radio"
                    />
                </Form.Group>
                <Row className={radioPostStatus === 'true' ? 'postAddressContainer hidden' : 'postAddressContainer'}>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationPostRegRegion">
                        <FormLabel className='required'>Регион</FormLabel>
                        <Form.Control
                            type="text"
                            name="postRegRegion"
                            value={form_Data.postRegRegion}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Регион'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationLegalRegCity">
                        <FormLabel className='required'>Населенный пункт</FormLabel>
                        <Form.Control
                            type="text"
                            name="postRegCity"
                            value={form_Data.postRegCity}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Населенный пункт'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Form.Group lg={4} as={Col} className="mb-3" controlId="validationPostRegStreet">
                            <FormLabel className='required'>Улица</FormLabel>
                            <Form.Control
                                type="text"
                                name="postRegStreet"
                                value={form_Data.postRegStreet}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Улица'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostRegHouse">
                            <FormLabel className='required'>Дом</FormLabel>
                            <Form.Control
                                type="text"
                                name="postRegHouse"
                                value={form_Data.postRegHouse}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Дом'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostRegCorpus">
                            <FormLabel >Корпус</FormLabel>
                            <Form.Control
                                type="text"
                                name="postRegCorpus"
                                value={form_Data.postRegCorpus}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Корпус'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostRegApp">
                            <FormLabel>Квартира</FormLabel>
                            <Form.Control
                                type="text"
                                name="postRegApp"
                                value={form_Data.postRegApp}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                placeholder='Квартира'
                            />
                        </Form.Group>
                        <Form.Group lg={2} as={Col} className="mb-3" controlId="validationPostPostalCode">
                            <FormLabel className='required'>Индекс</FormLabel>
                            <Form.Control
                                type="text"
                                name="postPostalCode"
                                value={form_Data.postPostalCode}
                                onChange={chngFn}
                                className='w-100 legal-form-1'
                                required
                                placeholder='Индекс'
                            />
                            <Form.Control.Feedback type="invalid">
                                Введите корректные данные
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Row>
            </CardBody>
        </Card>
    </> );
}
 
export default LeagalForm1;