import React, { FC, useContext} from "react";
import {Context} from "../../../../index";
import { observer } from "mobx-react-lite";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import selectFile from "../../../../img/icons/fileUpload.svg";
import infoCount from "../../../../img/icons/infoCount.svg";
import modalImage from "../../../../img/icons/settings-alt.svg";

import URL from "../../../../config/default.json"
import ModalGos from "../../../modals/ModalGos";

export const API_URL = URL.SERVER_URL_BASE;

const TechniqueDetail: FC = () => {
    const {store} = useContext(Context);
    function showTechniqueItem(count:any, variant = '') {
        var technique:any = store.techniqueInfo.technique;
        var index = 1;
        return (<>
            <div className="harvest-data p-0 pt-2">
                <Row className="harvest-data-bottom">
                    <Col xs={{ order: 2 }} lg={10}><Row>
                        {technique.map(function(data:any, idx:any){
                            if (index > count) {
                                return false;
                            }
                            const uploadTechniquePhotoPathElem = data.uploadTechniquePhotoPath[0];
                            const uploadTechniquePhotoPath = uploadTechniquePhotoPathElem ? uploadTechniquePhotoPathElem.split('uploads/').pop() : '';
                            if (data.isTypeOfTechnique === variant || variant === '') {
                                index++;
                                return (<Col lg={4}>
                                    <div className="harvest-data-bottom-item-info">
                                    {data.uploadTechniquePhotoPath ? (<>
                                        <div className="photo-technique-img loaded info d-flex position-relative"> <img src={API_URL + uploadTechniquePhotoPath} alt={data.nameOfTechnique} />
                                            <div className="develop-technique px-3">
                                                <div className="develop-technique-title">{data.nameOfTechnique}</div>
                                                <div className="develop-technique-year mb-2">{data.yearOfTechnique}</div>
                                            </div>
                                            <ModalGos classImg="img" class="develop-settings" name="" image={modalImage}/>
                                        </div>
                                    </>): (<></>)}
                                    </div>
                                    </Col>)
                            } else {
                                return (<></>)
                            }
                        }) 
                    }
                    </Row></Col>
                    <Col order="1" lg={2} ><div className="harvest-data-bottom-item info"><span><img className="me-2" src={infoCount} alt="="/>{index - 1}</span><span>eдиниц</span></div></Col>
                </Row>
            </div>
        </>);
    }
    return ( <>
     {store.isAuth && store.techniqueInfo && store.techniqueInfo.email ? (<>
        <Col className="mb-3" lg={12} ><Link className="card-link-container-title" to="/technique"><img className="me-3" src={selectFile} alt="Земля"/>Техника</Link></Col>
        <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
            <Tab eventKey="step1" title="Все">
                {showTechniqueItem(3)}
            </Tab>
            <Tab eventKey="step2" title="Транспорт">
                {showTechniqueItem(3, 'transport')}
            </Tab>
            <Tab eventKey="step3" title="Трактор">
                {showTechniqueItem(3, 'tractor')}
            </Tab>
            <Tab eventKey="step4" title="Прицепные">
                {showTechniqueItem(3, 'trailer')}
            </Tab>
            <Tab eventKey="step5" title="Специальное оборудование">
                {showTechniqueItem(3, 'specialEquipment')}
            </Tab>
        </Tabs>
        
    </>) : <></>}
    </> );
}
 
export default observer(TechniqueDetail);