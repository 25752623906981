import { React, useContext} from "react";
import { Button, Container, Form, Nav, Navbar } from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router-dom";
import {Context} from "../../index";

import logo from './../../img/logo/logo.png';
import searchBtn from './../../img/icons/search.svg';
import myData from './../../locale/ru.json';
import { Link, NavLink } from "react-router-dom";
import ModalLink from "../modals/ModalLink";

const Header = () => {
    const {store} = useContext(Context);
    const location = useLocation();
    const accountMenuArr = ['/account', '/cfa', '/legal-entity', '/representative-entity', '/information', '/harvest', '/technique', '/land', '/warehouse', '/stocks', '/my-cfa', '/history-transaction', '/dashboard-cfa'];
    const companyMenuClassArr = ['/account', '/legal-entity', '/representative-entity', '/information', '/harvest', '/technique', '/land', '/warehouse', '/stocks'];
    const dashboardMenuClassArr = ['/cfa', '/my-cfa', '/history-transaction', '/dashboard-cfa'];

    const accountMenu = accountMenuArr.indexOf(location.pathname ) !== -1 ? true : false;
    const companyMenuClass = companyMenuClassArr.indexOf(location.pathname ) !== -1 ? 'nav-link nav-link-account active' : 'nav-link nav-link-account';
    const dashboardMenuClass = dashboardMenuClassArr.indexOf(location.pathname ) !== -1 ? 'nav-link nav-link-account active' : 'nav-link nav-link-account';
    
    return (
        <header>
            <Navbar className={store.isAuth && accountMenu ? 'account-nav' : 'py-3'} collapseOnSelect expand="lg" bg="light" variant="light">
                <Container>
                    <NavLink className="me-5" to="/">
                        <div className="header-logo"><img src={logo} width="190" className="d-inline-block align-top header-logo-img" alt={myData.logo} /></div>
                    </NavLink>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
                        <Nav className="mr-auto">
                            
                            {store.isAuth && accountMenu ? (<>
                                <NavLink className={companyMenuClass}  to="/account">Моя Организация</NavLink>
                                <ModalLink class="nav-link nav-link-account" name="Сделки и ЦФА"/>
                                <NavLink className={dashboardMenuClass}  to="/my-cfa">Сделки и витрина ЦФА</NavLink>
                                </>
                            ) : (<>
                                <ModalLink class="nav-link" name={myData.nav.about}/>
                                <ModalLink class="nav-link" name={myData.nav.manufacturers}/>
                                <ModalLink class="nav-link" name={myData.nav.investors}/>
                                <ModalLink class="nav-link" name={myData.nav.informations}/>
                                <ModalLink class="nav-link" name={myData.nav.help}/>
                                <ModalLink class="nav-link" name={myData.nav.contacts}/>
                                </>
                            )
                            }
                        </Nav>
                        {store.isAuth && accountMenu ? (
                        <div className="search-bar">
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Поиск" />
                                </Form.Group>
                                <Button variant="icon" type="submit"><img src={searchBtn} width="17" height="17" alt="Поиск" /></Button>
                            </Form>
                        </div>
                        ) : (<></>)}
                        <div className="d-flex btn-container align-items-right">
                            {!store.isAuth ? (<>
                                    <div className="btn-outline-wrap-gold"><Link className="btn btn-outline" to="/login">{myData.buttons.login}</Link></div>
                                    <Link className="btn btn-primary ms-4" to="/registration">{myData.buttons.registration}</Link>
                                </>
                                
                            ) : (<>
                                <div className="align-self-center"><Link className="account-link" to="/account">{store.user.name}</Link></div>
                                <button className="btn btn-primary ms-4" onClick={() => store.logout()}>Выйти</button>
                                </>
                            )
                            }
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
           
        </header>
    );
}
 
export default observer(Header);