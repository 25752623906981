import {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormLabel, Modal, Row } from 'react-bootstrap';

import transactionImage from "../../img/icons/transaction.svg"
import TooltipInfo from '../tooltips/TooltipInfo';

const TransactionHistory:FC = () => {
    const {store} = useContext(Context);

    const countEl = 3;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        set_Validated(false);
    }

    const [showWithdrawal, setShowWithdrawal] = useState(false);
    const handleCloseWithdrawal = () => setShowWithdrawal(false);
    const handleShowWithdrawal = () => {
        setShowWithdrawal(true);
        set_ValidatedTransaction(false);
    }
    const [validated, set_Validated] = useState(false);
    const [validatedTransaction, set_ValidatedTransaction] = useState(false);
    const submitForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            set_Validated(false);
        } else {
            set_Validated(true);
        }
    }

    const submitTransactionForm = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            set_ValidatedTransaction(false);
        } else {
            set_ValidatedTransaction(true);
        }
    }

    

    const [form_Data, set_Form_Data] = useState({
        accountNumber: '',
        bankBik: '',
        bankName: '',
        corpAccount: '',
        bankInn: '',
        accountOwner: '',
        paymentReason: ''
    });

    const [form_Data_Transaction, set_Form_Data_Transaction] = useState({
        numberAccountFrom: '',
        freeAmount: '',
        sendAmount: '',
        totalAmount: ''
    });

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };

    const chngFnTransaction = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data_Transaction({
            ...form_Data_Transaction,
            [name]: value,
        });
    };

    return (<>
            {store.isAuth ? (<>
                <CfaAccountSubMenu class1="organization-link px-4" class2="organization-link px-4 active" class3="organization-link px-4"/>
                <Container>
                    <h2 className='mt-5'>Номер лицевого счета</h2>
                    <p className='text-small mb-1'>Номер банковского счета</p>
                    <div className='ammountOfAccount'><span>20 000 000</span>  руб. <TooltipInfo/></div>
                    <Row className='cfa-add-amount-wrap mt-5'>
                        <Col lg={4}>
                            <div className='cfa-add-amount'>
                                <div className='cfa-add-amount-title text-small mb-2'>Свободно</div>
                                <div className='cfa-add-amount-summ'><span className='amount'>20 000 000</span> <span className='text-small'>руб.</span></div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='cfa-add-amount'>
                                <div className='cfa-add-amount-title text-small mb-2'>Зарезервировано</div>
                                <div className='cfa-add-amount-summ'><span className='amount'>1 000 000</span> <span className='text-small'>руб.</span></div>
                            </div>
                        </Col>
                        <Col className="text-end" lg={2}>
                            <Button className="w-100" variant="primary" onClick={handleShow}>Пополнить счет</Button>

                            <Modal className="cfa-modal" show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                    <h4>Пополнение лицевого счёта</h4>
                                    <p>Для пополнения лицевого счёта совершите банковский перевод по указанным реквизитам:</p>
                                    <Card className="mb-3 mt-5">
                                        <CardBody>
                                            <Form noValidate validated={validated} onSubmit={submitForm}>
                                                <Form.Group lg={12} as={Col} className="mb-3" controlId="validationaccountNumber">
                                                    <FormLabel className='fw-normal'>Номер счета</FormLabel>
                                                    <Form.Control
                                                        type="number"
                                                        name="accountNumber"
                                                        value={form_Data.accountNumber}
                                                        onChange={chngFn}
                                                        className='w-100 legal-form-1'
                                                        required
                                                        placeholder='ХХХХ ХХХХ ХХХХ ХХХХ'
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Введите корректные данные
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Row>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbankBik">
                                                        <FormLabel className='fw-normal'>БИК банка получателя</FormLabel>
                                                        <Form.Control
                                                            type="number"
                                                            name="bankBik"
                                                            value={form_Data.bankBik}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='БИК банка'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbankBik">
                                                        <FormLabel className='fw-normal'>Банк получателя</FormLabel>
                                                        <Form.Control
                                                            type="text"
                                                            name="bankName"
                                                            value={form_Data.bankName}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='Наименование банка'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationcorpAccount">
                                                        <FormLabel className='fw-normal'>Корр. счет</FormLabel>
                                                        <Form.Control
                                                            type="number"
                                                            name="corpAccount"
                                                            value={form_Data.corpAccount}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='Корреспондентский счет'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbankInn">
                                                        <FormLabel className='fw-normal'>ИНН</FormLabel>
                                                        <Form.Control
                                                            type="text"
                                                            name="bankInn"
                                                            value={form_Data.bankInn}
                                                            onChange={chngFn}
                                                            className='w-100 legal-form-1'
                                                            required
                                                            placeholder='ИНН Банка'
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Введите корректные данные
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Form.Group lg={12} as={Col} className="mb-3" controlId="validationaccountOwner">
                                                    <FormLabel className='fw-normal'>Получатель</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="accountOwner"
                                                        value={form_Data.accountOwner}
                                                        onChange={chngFn}
                                                        className='w-100 legal-form-1'
                                                        required
                                                        placeholder='Получатель'
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Введите корректные данные
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group lg={12} as={Col} className="mb-3" controlId="validationpaymentReason">
                                                    <FormLabel className='fw-normal'>Назначение платежа</FormLabel>
                                                    <Form.Control
                                                        type="text"
                                                        name="paymentReason"
                                                        value={form_Data.paymentReason}
                                                        onChange={chngFn}
                                                        className='w-100 legal-form-1'
                                                        required
                                                        placeholder='Пополнение собственного Лицевого счета №Х. НДС не облагается'
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Введите корректные данные
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Button className="mt-2" variant="secondary" id="form-submit" type="submit">Отправить</Button>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Modal.Body>
                                <Modal.Footer>
                                    <p>Мы зачислим денежные средства в течение 1 рабочего дня <br/>
                                    Комиссия не взимается</p>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                        <Col className="text-end" lg={2}>
                            <Button className="w-100" variant="secondary" onClick={handleShowWithdrawal}>Вывод средств</Button>

                            <Modal className="cfa-modal" show={showWithdrawal} onHide={handleCloseWithdrawal} backdrop="static" keyboard={false} >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                    <h4>Поручение на вывод денежных средств</h4>
                                    <br/><br/>
                                    <br/><br/>
                                    <Form noValidate validated={validatedTransaction} onSubmit={submitTransactionForm}>
                                        <Row>
                                            <Form.Group lg={6} as={Col} className="mb-3" controlId="validationbnumberAccountFrom">
                                                <FormLabel className='fw-normal'>Счет списания</FormLabel>
                                                <Form.Control
                                                    type="number"
                                                    name="numberAccountFrom"
                                                    value={form_Data_Transaction.numberAccountFrom}
                                                    onChange={chngFnTransaction}
                                                    className='w-100 legal-form-1'
                                                    required
                                                    placeholder='Номер лицевого счета'
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Введите корректные данные
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group lg={6} as={Col} className="mb-3" controlId="validationfreeAmount">
                                                <FormLabel className='fw-normal'>Свободных средств, ₽</FormLabel>
                                                <Form.Control
                                                    type="text"
                                                    name="freeAmount"
                                                    value={form_Data_Transaction.freeAmount}
                                                    onChange={chngFnTransaction}
                                                    className='w-100 legal-form-1'
                                                    required
                                                    placeholder='Сумма свободных д/с на счёте'
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Введите корректные данные
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Form.Group lg={12} as={Col} className="mb-3 sendAmount" controlId="validationfsendAmount">
                                                <FormLabel className='fw-normal'>Сумма списания</FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="sendAmount"
                                                value={form_Data_Transaction.sendAmount}
                                                onChange={chngFnTransaction}
                                                className='w-100 legal-form-1'
                                                required
                                                placeholder='Введите сумму'
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Введите корректные данные
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <p className="form-text">Из суммы списания будет удержано:</p>
                                        <p className="form-text text-small">НДФЛ за текущий налоговый период: <span className='amountTax'>ХХ</span></p>
                                        <p className="form-text text-small">Комиссия за исполнение платёжного поручения: ХХ</p>
                                        <br/><br/>
                                        <Form.Group lg={12} as={Col} className="mb-3 totalAmount" controlId="validationftotalAmount">
                                                <FormLabel className='fw-normal'>Итого к выводу</FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="totalAmount"
                                                value={form_Data_Transaction.totalAmount}
                                                onChange={chngFnTransaction}
                                                className='w-100 legal-form-1'
                                                required
                                                placeholder='XXXXXXX'
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Введите корректные данные
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className='d-flex'>
                                            <Button className="mt-2 me-3" variant="primary" id="form-submit" type="submit">Отправить поручение</Button>
                                            <div className='btn-outline-wrap-gray mt-2 d-inline-flex'><Button variant="outline" id="form-submit" type="submit">Реквизиты зачисления денежных средств</Button></div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                    <CardTitle className='mt-5'>История операций</CardTitle>
                    <Card className="mb-3">
                        <CardBody>
                            <div className='cfa-operation-day'>
                                <div className='cfa-operation-title'>
                                    <Row className="cfa-table-header me-0 ms-0">
                                        <Col className="text-end" lg={10}></Col>
                                        <Col className="text-end" lg={2}>Сумма сделки</Col>
                                    </Row>
                                </div>
                                <div className='cfa-operation-day-item'>
                                    <div className='cfa-operation-day-item-data mt-4'>{new Date('05/10/2024').toISOString().slice(0, 10).split('-').reverse().join('.')}</div>
                                    {(() => {
                                        const element = [];

                                        for (let i = 1; i <= countEl; i++) {
                                            element.push(
                                            <Row className={i === 1 ? 'align-items-center cfa-table-body mt-4 me-0 ms-0 sell' : 'align-items-center cfa-table-body mt-4 me-0 ms-0'}>
                                                <Col className="ps-0" lg={1}>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='cfa-table-sub-time me-3'>10:{i}0</div>
                                                        <img className="me-3 field-img" src={transactionImage} alt="Эмитент"/>
                                                    </div>
                                                </Col>
                                                <Col lg={9} className='cfa-table-title '><span>{i === 1 ? 'Продажа' : 'Комиссия за ХХХ'} </span></Col>
                                                <Col className="text-end sell-price" lg={2}>{i === 1 ? '+' : '-'}{i}{i} 000 000 руб.</Col>
                                            </Row>);
                                        }

                                    return element;
                                    })()}
                                </div>
                            </div>
                            <div className='cfa-operation-day mt-4'>
                                <div className='cfa-operation-day-item'>
                                    <div className='cfa-operation-day-item-data mt-4'>{new Date('04/10/2024').toISOString().slice(0, 10).split('-').reverse().join('.')}</div>
                                    {(() => {
                                        const element = [];

                                        for (let i = 1; i <= countEl; i++) {
                                            element.push(
                                            <Row className={i === 3 ? 'align-items-center cfa-table-body mt-4 me-0 ms-0 transfer' : 'align-items-center cfa-table-body mt-4 me-0 ms-0'}>
                                                <Col className="ps-0" lg={1}>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='cfa-table-sub-time me-3'>10:{i}0</div>
                                                        <img className="me-3 field-img" src={transactionImage} alt="Эмитент"/>
                                                    </div>
                                                </Col>
                                                <Col lg={9} className='cfa-table-title '><span>{i === 3 ? 'Вывод со счета' : 'Первичное приобретение'} </span></Col>
                                                <Col className="text-end transfer-price" lg={2}>-{i}{i} 000 000 руб.</Col>
                                            </Row>);
                                        }

                                    return element;
                                    })()}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Container>

            </>) : (<></>)}
        </>
    );
}
 
export default observer(TransactionHistory);