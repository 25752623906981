import {FC, useContext} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import CfaAccountSubMenu from '../account/parts/CfaAccountSubMenu';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import emitImage from "../../img/icons/emiter-default.svg"

const DashboardCfa:FC = () => {
    const {store} = useContext(Context);
    const countEl = 4;
    const countEl2 = 2;

    return (<>
            {store.isAuth ? (<>
                <CfaAccountSubMenu class1="organization-link px-4" class2="organization-link px-4" class3="organization-link px-4 active"/>
                <Container>
                <h2 className='mt-5'>Витрина ЦФА</h2>
                <div className='dashboard-cfa mt-5'>
                    <Tabs defaultActiveKey="step1" variant="tabs" id="info-tab-example" className="mb-3" >
                        <Tab eventKey="step1" title="Первичное приобретение ЦФА">
                            <Row className="cfa-table-header mt-5 me-0 ms-0">
                                <Col lg={4}>Наименование</Col>
                                <Col lg={2}>Эмитент</Col>
                                <Col className="text-center" lg={1}>Количество</Col>
                                <Col className="text-end" lg={1}>Цена</Col>
                                <Col className="text-end text-center" lg={2}>Тип сделки</Col>
                                <Col className="text-center" lg={1}>Стоимость</Col>
                                <Col className="text-center" lg={1}></Col>
                            </Row>
                            {(() => {
                                const element = [];

                                for (let i = 1; i <= countEl; i++) {
                                    element.push(
                                    <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                        <Col className="ps-0" lg={4}>
                                            <div className='cfa-table-title'>
                                                <div className='cfa-table-sub-title'>Digital-Grain_{i}00</div>
                                                <div className='cfa-table-name'>ООО «Поле» — денежное требование №{i}</div>
                                            </div>
                                        </Col>
                                        <Col lg={2} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>ООО «Поле»</span></Col>
                                        <Col className="text-center" lg={1}>1{i}</Col>
                                        <Col className="text-end" lg={1}>{i} 000 000</Col>
                                        <Col className="text-end text-center" lg={2}>Продажа ЦФА</Col>
                                        <Col className="text-center" lg={1}>{i}{i}50 000 ₽</Col>
                                        <Col className="text-center" lg={1}>
                                            <div className='btn-outline-wrap-gold mt-3 mb-4'>
                                                <button className='btn btn-outline'>Купить</button>
                                            </div>
                                        </Col>
                                    </Row>);
                                }

                            return element;
                            })()}
                        </Tab>
                        <Tab eventKey="step2" title="Вторичное обращение ЦФА">
                            <Row className="cfa-table-header mt-5 me-0 ms-0">
                                <Col lg={4}>Наименование</Col>
                                <Col lg={2}>Эмитент</Col>
                                <Col className="text-center" lg={1}>Количество</Col>
                                <Col className="text-end" lg={1}>Цена</Col>
                                <Col className="text-end text-center" lg={2}>Тип сделки</Col>
                                <Col className="text-center" lg={1}>Стоимость</Col>
                                <Col className="text-center" lg={1}></Col>
                            </Row>
                            {(() => {
                                const element = [];

                                for (let i = 1; i <= countEl2; i++) {
                                    element.push(
                                    <Row className="align-items-center cfa-table-body mt-4 me-0 ms-0">
                                        <Col className="ps-0" lg={4}>
                                            <div className='cfa-table-title'>
                                                <div className='cfa-table-sub-title'>Digital-Grain_{i}00</div>
                                                <div className='cfa-table-name'>ООО «Поле» — денежное требование №{i}</div>
                                            </div>
                                        </Col>
                                        <Col lg={2} className='align-items-center'><img className="me-3" src={emitImage} alt="Эмитент"/><span>ООО «Поле»</span></Col>
                                        <Col className="text-center" lg={1}>1{i}</Col>
                                        <Col className="text-end" lg={1}>{i} 00 000</Col>
                                        <Col className="text-end text-center" lg={2}>Покупка ЦФА</Col>
                                        <Col className="text-center" lg={1}>{i}{i}20 000 ₽</Col>
                                        <Col className="text-center" lg={1}>
                                            <div className='btn-outline-wrap-gold mt-3 mb-4'>
                                                <button className='btn btn-outline'>Купить</button>
                                            </div>
                                        </Col>
                                    </Row>);
                                }

                            return element;
                            })()}
                        </Tab>
                    </Tabs>
                </div>
                </Container>
            </>) : (<></>)}
        </>
    );
}
 
export default observer(DashboardCfa);